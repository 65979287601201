@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon.eot?ln3w4s');
	src: url('fonts/icomoon.eot?ln3w4s#iefix') format('embedded-opentype'), url('fonts/icomoon.woff2?ln3w4s') format('woff2'), url('fonts/icomoon.ttf?ln3w4s') format('truetype'), url('fonts/icomoon.woff?ln3w4s') format('woff'), url('fonts/icomoon.svg?ln3w4s#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
	content: '\e905';
}
.icon-link:before {
	content: '\e906';
}
.icon-linkedin:before {
	content: '\e907';
}
.icon-twitter:before {
	content: '\e908';
}
.icon-time:before {
	content: '\e904';
	color: #402661;
}
.icon-arrow-big:before {
	content: '\e900';
}
.icon-heart-fill .path1:before {
	content: '\e902';
	color: #402661;
}
.icon-heart-fill .path2:before {
	content: '\e968';
	margin-left: -1.095703125em;
	color: #402661;
}
.icon-bin:before {
	content: '\e901';
	color: #402661;
}
.icon-smile:before {
	content: '\e903';
}
.icon-heart:before {
	content: '\e909';
}
.icon-heart2:before {
	content: '\e902';
}
.icon-menu:before {
	content: '\e90a';
	color: #442763;
}
.icon-plus:before {
	content: '\e90c';
}
.icon-search:before {
	content: '\e90f';
}
.icon-minus:before {
	content: '\e969';
}
.icon-close:before {
	content: '\e90e';
}
.icon-arrow:before {
	content: '\e96d';
}
.icon-filter:before {
	content: '\e96e';
}
